import React from 'react';

const PopperContext = React.createContext({
  show: false,
  open: (target) => {},
});

export const PopperProvider = PopperContext.Provider;

export const PopperConsumer = PopperContext.Consumer;

PopperContext.displayName = 'popper-context';

export default PopperContext;