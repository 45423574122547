import request from '@/utils/request';

interface ResponseData {
  code: number,
}

export default {
  /**
   * sign up
   */
  signup: (params: object) => request.post('/signup', params),
  /**
   * sign in
   */
  signin: (params: object) => request.post('/login', params),
  /**
   * get user info
   */
  getUserInfo: () => request.get('/me'),
  /**
   * update user info
   */
  updateUserInfo: (params: object) => request.put('/me', params),
  /**
   * reset password
   */
  resetPassword: (params: object) => request.put('/passwords', params),
  /**
   * logout
   */
  logout: () => request.delete('/logout'),
  /**
   * resend confirmation
   */
  resendConfirmationEmail: () => request.post('/confirmation'),
  /**
   * change password
   */
  changePassword: (params: object) => request.post('/passwords', params),
};
