import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  AppBar, Toolbar, InputBase, SwipeableDrawer, IconButton, Typography, Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FaceIcon from '@material-ui/icons/Face';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import { connect } from 'react-redux';
import { IConnectUser } from '@/interfaces/IConnectState';
import ElevationScroll from './elevation-scroll';
import Style from './header.module.scss';
import { KEYBOARD } from '@/utils/constants';
import Logo from '@/images/logo.svg';
import store from '@/store';

interface IProps extends IConnectUser, RouteComponentProps {
  onSearchChange?: Function,
  router?: any,
}

interface IState {
  menuOpen: boolean,
  search: string,
  anchorEl: any
}

class Header extends React.Component<IProps, IState> {
  state: IState = {
    menuOpen: false,
    search: '',
    anchorEl: null,
  }

  accountMenuId = 'account-menu';

  componentDidMount() {
    const {
      history,
      location: {
        search = '',
      },
    } = this.props;

    history.listen(this.handleRouteChange);
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({
      search: (query.search as string) || '',
    });
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;
    const {
      location: {
        search,
      },
    } = this.props;
    if (search !== prevSearch) {
      const query = qs.parse(search, { ignoreQueryPrefix: true });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        search: query.search || '',
      });
    }
  }

  handleRouteChange = () => {
    this.handleMenuClose();
  }

  handleOpenMenu = () => {
    this.setState({
      menuOpen: true,
    });
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    });
  }

  handleLinkButtonClick = (link: any) => {
    const {
      history,
    } = this.props;
    history.push(link);
    this.handleAccountMenuClose();
  }

  handleSearchChange = (event: any) => {
    this.setState({
      search: event.target.value,
    });
  }

  handleSearchKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      keyCode,
    } = event;
    const {
      history,
    } = this.props;
    const {
      search,
    } = this.state;
    if (keyCode === KEYBOARD.ENTER) {
      history.push(`/search?search=${search}`);
    }
  }

  handleAccountMenuOpen = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleAccountMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      menuOpen,
      search = '',
      anchorEl,
    } = this.state;

    const {
      user,
    } = this.props;
    const {
      userInfo,
      logout,
      logging,
    } = user;

    return (
      <>
        <ElevationScroll>
          <AppBar className={Style.menuBar} color="inherit">
            <Toolbar>
              <Typography noWrap>
                <Link to="/home">
                  <img height="40" src={Logo} alt="logo" />
                </Link>
              </Typography>
              <div className={Style.searchWrap}>
                <i className="iconfont home_search" />
                <InputBase
                  className={Style.serachInput}
                  value={search}
                  onChange={this.handleSearchChange}
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  onKeyDown={this.handleSearchKeydown}
                />
              </div>
              <div className="flex-one" />
              <IconButton
                edge="end"
                color="inherit"
                className={Style.mobileOnlyItem}
                onClick={() => this.handleLinkButtonClick('/home')}
              >
                <HomeIcon />
              </IconButton>
              <Button
                className={Style.mobileInvisibleItem}
                onClick={() => this.handleLinkButtonClick('/home')}
                startIcon={<HomeIcon />}
              >
                Home
              </Button>
              <IconButton
                edge="end"
                color="inherit"
                className={Style.mobileOnlyItem}
                onClick={() => this.handleLinkButtonClick('/search/mobile')}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                className={Style.mobileOnlyItem}
                edge="end"
                color="inherit"
                onClick={this.handleAccountMenuOpen}
                aria-label="Account Menu"
                aria-controls={this.accountMenuId}
                aria-haspopup="true"
              >
                <AccountCircleIcon />
              </IconButton>
              <Button
                className={Style.mobileInvisibleItem}
                onClick={this.handleAccountMenuOpen}
                aria-label="Account Menu"
                aria-controls={this.accountMenuId}
                aria-haspopup="true"
                startIcon={<AccountCircleIcon />}
              >
                Account
              </Button>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={this.accountMenuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={anchorEl != null}
          onClose={this.handleAccountMenuClose}
        >
          {
            userInfo ? (
              <>
                <MenuItem>
                  Hi, {userInfo.attributes.name}
                </MenuItem>
                <MenuItem onClick={() => this.handleLinkButtonClick('/favorite')}>
                  <IconButton aria-label="Favorites" color="inherit">
                    <BookmarkIcon />
                  </IconButton>
                  Favorites
                </MenuItem>
                <MenuItem onClick={() => this.handleLinkButtonClick('/history')}>
                  <IconButton aria-label="History" color="inherit">
                    <HistoryIcon />
                  </IconButton>
                  History
                </MenuItem>
                <MenuItem onClick={() => store.dispatch({ type: 'LOGOUT' })}>
                  <IconButton aria-label="Sign Out" color="inherit">
                    <ExitToAppIcon />
                  </IconButton>
                  Sign Out
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={() => this.handleLinkButtonClick('/auth/signin')}>
                  <IconButton aria-label="Sign In" color="inherit">
                    <FaceIcon />
                  </IconButton>
                  Sign In
                </MenuItem>
                <MenuItem onClick={() => this.handleLinkButtonClick('/auth/signup')}>
                  <IconButton aria-label="Sign Up" color="inherit">
                    <GroupAddIcon />
                  </IconButton>
                  Sign Up
                </MenuItem>
              </>
            )
          }
          <MenuItem onClick={() => this.handleLinkButtonClick('/setting')}>
            <IconButton aria-label="Setting" color="inherit">
              <SettingsIcon />
            </IconButton>
            Setting
          </MenuItem>
        </Menu>
        <Toolbar />
      </>
    );
  }
}

const mapStateToProps = (state: IConnectUser) => ({
  user: state.user,
});

export default withRouter(connect((state: IConnectUser) => ({
  user: state.user,
}))(Header));
