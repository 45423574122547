import { combineReducers } from 'redux';
import home from './home';
import user from './user';
import language from './language';

export interface IAudioState {
  src?: string, // audio link
  loading?: boolean, // check audio is loading
  playing?: boolean, // check audio is playing
  canPlay?: boolean, // check audio can play
  currentTime?: number | undefined, // current time
  startTime?: number | undefined, // range start
  endTime?: number | undefined, // range end
  clickTime?: number | null, // clickTime
  totalTime?: number | undefined,
}

export interface IRootState {
  home: {
    audio: IAudioState
  }
}

export default combineReducers({
  home,
  user,
  language,
});
