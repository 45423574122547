import {
  put, takeEvery, takeLatest, call,
} from 'redux-saga/effects';
import Api from '@/api';
import { get, toLower } from 'lodash';
import en from '../languages/en';
import { IAction } from './index';

/**
 * set total message
 * @param msg
 */
function* setSnakebarMessage(action: IAction) {
  yield put({
    type: 'SET_TOAST_MESSAGE',
    data: action.data,
  });
}

function* searchDictionary(action: IAction) {
  yield put({
    type: 'SET_HOME_DATA',
    data: {
      dictionaryLoading: true,
    },
  });
  const ret = yield call(Api.materials.getWordDictionary, action.data);
  yield put({
    type: 'SET_HOME_DATA',
    data: {
      wordDictionary: get(ret.data, 'attributes.definitions'),
      dictionaryLoading: false,
    },
  });
}

function* switchLanguage(action: IAction) {
  const {
    data: {
      localLanguage,
    },
  } = action;
  if (localLanguage) {
    try {
      const ret = yield import(`../languages/${localLanguage}`);
      yield put({
        type: 'CHANGE_LANGUAGE_DATA',
        data: {
          messages: ret.default.messages,
        },
      });
    } catch (err) {
      console.error(`No language [${localLanguage}] defined, use en fallback.`);
      yield put({
        type: 'CHANGE_LANGUAGE_DATA',
        data: {
          messages: en.messages,
        },
      });
    }
  }
}

/**
 * search change
 * @param search
 */
// function* searchChange(search: string) {
//   yield put({ type: 'SET_SEARCH', search });
// }

export default function* () {
  yield takeEvery('SHOW_TOAST', setSnakebarMessage);
  yield takeLatest('SEARCH_DICTIONARY', searchDictionary);
  yield takeLatest('SWITCH_LANGUAGE', switchLanguage);
}
