import request from '@/utils/request';
import qs from 'qs';
import cachable_path from './cachable_path';

export default {
  /**
   * get material list
   */
  getMaterialsList: (params: object) => request.get(cachable_path('/materials'), { params }),
  /**
   * get material tags
   */
  getMaterialTags: (params: object) => request.get(cachable_path('/tags'), { params }),
  /**
   * get material tag
   */
  getMaterialTag: (uid: string) => request.get(cachable_path(`/tags/${uid}`)),
  /**
   * get material detail
   */
  getMaterialDetail: (id: string, params: object) => request.get(cachable_path(`/materials/${id}`), { params }),
  /**
   * get word dictionary
   */
  getWordDictionary: (word: string) => {
    const lan = localStorage.getItem('language');
    return request.get(`/dictionary${qs.stringify({
      word,
      language_code: lan,
    }, {
      addQueryPrefix: true,
    })}`);
  },
};
