import { IAction } from '@/sagas/index';

const initState = {
  userInfo: null,
  logging: false,
  logout: false,
};

export default (state = initState, action: IAction) => {
  switch (action.type) {
  case 'SET_USER_DATA':
    return { ...state, ...action.data };
  default:
    return state;
  }
};
