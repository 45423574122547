import { IAction } from '@/sagas/index';

const defaultAudioState = {
  src: '', // audio link
  playing: false, // check audio is playing
  canPlay: false, // check audio can play
  currentTime: undefined, // current time
  startTime: undefined, // range start
  endTime: undefined, // range end
  clickTime: null,
  totalTime: undefined, // audio total time
};

const initState = {
  title: 'home',
  tagsMap: {},
  search: '',
  toast: {
    type: undefined,
    msg: '',
  }, // global toast message,
  toastOpen: false,
  showHeader: true,
  audio: { ...defaultAudioState },
  wordDictionary: [],
  dictionaryLoading: false,
};

export default (state = initState, action: IAction) => {
  switch (action.type) {
  case 'SET_TOAST_MESSAGE':
    return { ...state, toast: { ...state.toast, ...action.data }, toastOpen: true };
  case 'HIDE_TOAST':
    return { ...state, toastOpen: false };
  case 'SHOW_HEADER':
    return { ...state, showHeader: true };
  case 'HIDE_HEADER':
    return { ...state, showHeader: false };
  case 'RESET_AUDIO':
    return { ...state, audio: { ...defaultAudioState } };
  case 'SET_AUDIO':
    return { ...state, audio: { ...state.audio, ...action.data } };
  case 'SET_HOME_DATA':
    return { ...state, ...action.data };
  default:
    return state;
  }
};
