import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME,
});

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

export default history;
