/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component: C, ...rest }: any): any => {
  const isLogged = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={(props) => (isLogged ? <C {...props} /> : <Redirect to="/auth" />)}
    />
  );
};

export default withRouter(AuthRoute);
