import axios from 'axios';
import _ from 'lodash';
import { deepKeyToCamelCase, filterUndefined } from './index';


axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use((config) => {
  config.headers.common.Authorization = localStorage.getItem('token');
  if (config.params) {
    config.params = filterUndefined(config.params);
  }
  if (config.data && config.headers['Content-Type'] !== 'multipart/form-data') {
    config.data = filterUndefined(config.data);
  }
  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => {
  if (response.status === 200 || response.status === 204) {
    if (response.headers.authorization) {
      localStorage.setItem('token', response.headers.authorization);
      response.data.data.token = response.headers.authorization;
    }
    return deepKeyToCamelCase(Object.assign(response.data, { code: response.status }));
  }
  return Promise.reject(response);
}, ({ response }) => {
  if (response.status === 400 || response.status === 422) {
    return deepKeyToCamelCase(Object.assign(response.data, {
      code: response.status,
      error: `${response.data.error} ${_.chain(response.data.details).toPairs().map((i) => i.join(' ')).flatten()
        .join(',')
        .value()}`,
    }));
  }
  if (response.status === 401) {
    if (response.config.url.indexOf('login') > -1) {
      return deepKeyToCamelCase(Object.assign(response.data, {
        code: response.status,
        error: 'Incorrect email or password. Please double-check and try again.',
      }));
    }
    localStorage.removeItem('token');
    window.location.href = '/auth/signin';
  }
  return Promise.reject(response);
});

export default axios;
