import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { IConnectLanguage } from '@/interfaces/IConnectState';
import './index.scss';
import HttpsRedirect from 'react-https-redirect';
import initReactFastclick from 'react-fastclick';
import ReactGA from 'react-ga';
import PopperProvider from '@/components/popper-dictionary/provider';

import * as serviceWorker from './serviceWorker';
import MainLayout from './layout';
import store from './store';

ReactGA.initialize('UA-178517563-1');
ReactGA.pageview(window.location.pathname);

store.dispatch({
  type: 'INIT_USER_INFO',
});

initReactFastclick();


interface IIndexProps extends IConnectLanguage {}


const Index = (props: IIndexProps) => {
  const {
    language: {
      localLanguage,
      messages,
    },
  } = props;

  return (
    <IntlProvider locale={localLanguage} messages={messages}>
      <Helmet>
        <title>Learn English via Audio Books - Langira</title>
        <meta name="description" content="Langira helps English learners to improve English via thousands of bestselling audio books and articles." />
      </Helmet>
      <PopperProvider>
        <MainLayout />
      </PopperProvider>
    </IntlProvider>
  );
};
const ConnectIndex = connect((state: IIndexProps) => ({
  language: state.language,
}))(Index);

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <ConnectIndex />
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
