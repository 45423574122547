

import React from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Style from './index.module.scss';

interface IProps {
  value: string,
  onChange: Function,
}

const LanguageSelect = (props: IProps) => {

  const {
    value,
    onChange,
  } = props;

  return (
    <Select
      className={Style.languageSelect}
      labelId="choose-language-select-label"
      id="choose-language-select"
      value={value}
      onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target) { onChange(event.target.value as string); }
      }}
    >
      <MenuItem value="af">Afrikaans (Afrikaans)</MenuItem>
      <MenuItem value="ar">العربية (Arabic)</MenuItem>
      <MenuItem value="bg">Български (Bulgarian)</MenuItem>
      <MenuItem value="bn">বাংলা (Bangla)</MenuItem>
      <MenuItem value="bs">bosanski (latinica) (Bosnian (Latin))</MenuItem>
      <MenuItem value="ca">Català (Catalan)</MenuItem>
      <MenuItem value="zh-Hans">简体中文 (Chinese Simplified)</MenuItem>
      <MenuItem value="cs">Čeština (Czech)</MenuItem>
      <MenuItem value="cy">Welsh (Welsh)</MenuItem>
      <MenuItem value="da">Dansk (Danish)</MenuItem>
      <MenuItem value="de">Deutsch (German)</MenuItem>
      <MenuItem value="el">Ελληνικά (Greek)</MenuItem>
      <MenuItem value="es">Español (Spanish)</MenuItem>
      <MenuItem value="et">Eesti (Estonian)</MenuItem>
      <MenuItem value="fa">Persian (Persian)</MenuItem>
      <MenuItem value="fi">Suomi (Finnish)</MenuItem>
      <MenuItem value="ht">Haitian Creole (Faroese)</MenuItem>
      <MenuItem value="fr">Français (French)</MenuItem>
      <MenuItem value="he">עברית (Hebrew)</MenuItem>
      <MenuItem value="hi">हिंदी (Hindi)</MenuItem>
      <MenuItem value="hr">Hrvatski (Croatian)</MenuItem>
      <MenuItem value="hu">Magyar (Hungarian)</MenuItem>
      <MenuItem value="id">Indonesia (Indonesian)</MenuItem>
      <MenuItem value="is">Íslenska (Icelandic)</MenuItem>
      <MenuItem value="it">Italiano (Italian)</MenuItem>
      <MenuItem value="ja">日本語 (Japanese)</MenuItem>
      <MenuItem value="ko">한국어 (Korean)</MenuItem>
      <MenuItem value="lt">Lietuvių (Lithuanian)</MenuItem>
      <MenuItem value="lv">Latviešu (Latvian)</MenuItem>
      <MenuItem value="mt">Il-Malti (Maltese)</MenuItem>
      <MenuItem value="ms">Melayu (Malay)</MenuItem>
      <MenuItem value="mww">Hmong Daw (Hmong Daw)</MenuItem>
      <MenuItem value="nl">Nederlands (Dutch)</MenuItem>
      <MenuItem value="nb">Norsk (Norwegian)</MenuItem>
      <MenuItem value="pl">Polski (Polish)</MenuItem>
      <MenuItem value="pt">Português (Brasil) (Portuguese (Brazil))</MenuItem>
      <MenuItem value="ro">Română (Romanian)</MenuItem>
      <MenuItem value="ru">Русский (Russian)</MenuItem>
      <MenuItem value="sk">Slovenčina (Slovak)</MenuItem>
      <MenuItem value="sl">Slovenščina (Slovenian)</MenuItem>
      <MenuItem value="sr-Latn">srpski (latinica) (Serbian (Latin))</MenuItem>
      <MenuItem value="sv">Svenska (Swedish)</MenuItem>
      <MenuItem value="sw">Kiswahili (Swahili)</MenuItem>
      <MenuItem value="ta">தமிழ் (Tamil)</MenuItem>
      <MenuItem value="th">ไทย (Thai)</MenuItem>
      <MenuItem value="tlh-Latn">Klingon (Latin) (Klingon (Latin))</MenuItem>
      <MenuItem value="tr">Türkçe (Turkish)</MenuItem>
      <MenuItem value="uk">Українська (Ukrainian)</MenuItem>
      <MenuItem value="ur">اردو (Urdu)</MenuItem>
      <MenuItem value="vi">Tiếng Việt (Vietnamese)</MenuItem>
    </Select>
  )
}

export default LanguageSelect;