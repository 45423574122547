import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { toLower } from 'lodash';
import rootReducer from './reducers';
import rootSagas from './sagas';
import languages from './languages';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(sagaMiddleware),
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION__) ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f:any) => f,
  ),
);

const userSetLanguage = localStorage.getItem('language') || '';

if (userSetLanguage) {
  // not default language, load new language
  if (userSetLanguage !== 'en') {
    const lan = `./languages/${toLower(userSetLanguage)}`;
    console.log(lan);
    import(lan).then((ret) => {
      console.log(ret);
      store.dispatch({
        type: 'CHANGE_LANGUAGE_DATA',
        data: {
          messages: ret.default.messages,
        },
      });
    }).catch((error) => {
      console.error(`No language [${userSetLanguage}] defined, use en fallback.`);
      store.dispatch({
        type: 'CHANGE_LANGUAGE_DATA',
        data: {
          messages: languages.en.messages,
        },
      });
    });
  }
}


sagaMiddleware.run(rootSagas);

export default store;
