import request from '@/utils/request';

export default {
  /**
   * create active
   */
  createActivity: (params: object) => request.post('/activities', params),
  /**
   * get active list
   */
  getActivities: (params: object) => request.get('/activities', { params }),
};
