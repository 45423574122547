import {
  put, takeLatest, select,
} from 'redux-saga/effects';
import Api from '@/api';
import history from '@/routes/history';
import { IAction } from './index';

function* userLogin(action: IAction) {
  yield put({
    type: 'SET_USER_DATA',
    data: {
      logging: true,
    },
  });
  const ret = yield Api.user.signin(action.data);
  if (ret.code === 200) {
    history.replace('/home');
    yield put({
      type: 'SET_USER_DATA',
      data: {
        userInfo: ret.data,
        logging: false,
      },
    });
    yield put({
      type: 'SHOW_TOAST',
      data: {
        type: 'success',
        msg: 'Sign in successfully',
      },
    });
  } else {
    yield put({
      type: 'SET_USER_DATA',
      data: {
        logging: false,
      },
    });
    yield put({
      type: 'SHOW_TOAST',
      data: {
        type: 'error',
        msg: ret.error,
      },
    });
  }
}

/**
 * first load and init user info to reducer
 * when sessionStoreage's token is empty, do nothing
 * @param action
 */
function* userLoginAndInitUserInfo() {
  const token = localStorage.getItem('token');
  const user = yield select((state) => state.user);
  if (token && !user.userInfo) {
    const getUserInfoRet = yield Api.user.getUserInfo();
    yield put({
      type: 'SET_USER_DATA',
      data: {
        userInfo: getUserInfoRet.data,
        logging: false,
      },
    });
  }
}

/**
 * update user info
 */
function* updateUserInfo() {
  const getUserInfoRet = yield Api.user.getUserInfo();
  yield put({
    type: 'SET_USER_DATA',
    data: {
      userInfo: getUserInfoRet.data,
      logging: false,
    },
  });
}

/**
 * user logout
 */
function* userLogout() {
  yield put({
    type: 'SET_USER_DATA',
    data: {
      logout: true,
    },
  });
  yield Api.user.logout();
  yield put({
    type: 'SET_USER_DATA',
    data: {
      userInfo: null,
      logout: false,
    },
  });
  yield put({
    type: 'SHOW_TOAST',
    data: {
      type: 'success',
      msg: 'Good bye!',
    },
  });
  localStorage.removeItem('token');
  window.location.href = '/home';
}

export default function* () {
  yield takeLatest('LOGIN', userLogin);
  yield takeLatest('LOGOUT', userLogout);
  yield takeLatest('INIT_USER_INFO', userLoginAndInitUserInfo);
  yield takeLatest('UPDATE_USER_INFO', updateUserInfo);
}
