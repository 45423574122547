import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createPopper } from '@popperjs/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import store from '@/store';
import { PopperProvider } from './index';
import Style from './index.module.scss';
import ClearIcon from '@material-ui/icons/Clear';

class Provider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      word: '',
      dictionary: [],
    }
    this.dictionaryTip = React.createRef(null);
  }

  componentDidMount () {
    this.clickHandler = (event) => {
      const target = event.target;
      if (!(target && (target.dataset.popperPlacement || target.getAttribute('w') === '')) && this.popperIns) {
        this.setState({
          visible: false,
        })
        this.popperIns.destroy();
        this.popperIns = null;
      }
    };
    document.addEventListener('click', this.clickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickHandler);
  }

  openPopover = async (target) => {
    if (this.dictionaryTip.current) {
      this.popperIns = createPopper(target, this.dictionaryTip.current, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      });
      this.setState({
        visible: true,
      })
      const word = target.innerText;
      store.dispatch({
        type: 'SET_HOME_DATA',
        data: {
          wordDictionary: [],
        },
      })
      this.setState({
        word: target.innerText,
        dictionary: [],
      })
      store.dispatch({
        type: 'SEARCH_DICTIONARY',
        data: word,
      })
    }
  }

  render() {
    const {
      visible,
      word,
    } = this.state;

    const {
      children,
      wordDictionary = '',
      dictionaryLoading,
    } = this.props;

    return (
      <PopperProvider value={{
        open: this.openPopover
      }}>
        { children }
        {
          createPortal(
            <div ref={this.dictionaryTip} className={Style.popoverContent} style={{ display: visible ? 'block' : 'none' }}>
              <div className={Style.word}>{ word }</div>
              <div className={Style.dictionary}>
                { 
                  !dictionaryLoading ? (
                    !wordDictionary.length ? (
                      <span>No definitions found for this word</span>
                    ) : (
                      <ul className={Style.wordTypeList}>
                        {
                          wordDictionary.map(i => (
                            <li key={i.d}>{i.t} - {i.d}</li>
                          ))
                        }
                      </ul>
                    )
                  ) : <CircularProgress size={20} />
                }
                <div className={Style.clearIcon}><ClearIcon /></div>
                </div>
              <div className={Style.popperArrow} data-popper-arrow></div>
            </div>,
            document.body
          )
        }
      </PopperProvider>
    )
  }
}

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default connect(state => {
  return {
    wordDictionary: state.home.wordDictionary,
    dictionaryLoading: state.home.dictionaryLoading
  }
})(Provider);