export enum MEDIA_TYPE {
  AUDIO = 1,
  VIDEO = 2
}

export enum NODE_TYPE {
  TEXT = 3,
  ATTR = 2,
  ELEMENT = 1,
}

export enum KEYBOARD {
  ENTER = 13,
}
