import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

interface IProps {
  children: React.ReactElement;
}

const ElevationScroll = (props: IProps) => {
  const { children } = props;
  const trigger = useScrollTrigger({ target: document.body });

  return (
    <Slide direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default ElevationScroll;
