import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from './auth-route';
const Home = React.lazy(() => import('../pages/home'));
const Search = React.lazy(() => import('../pages/search'));
const MobileSearch = React.lazy(() => import('../pages/search/mobile'));
const MaterialDetail = React.lazy(() => import('../pages/material-detail'));
const Signup = React.lazy(() => import('../pages/auth/signup'));
const Singin = React.lazy(() => import('../pages/auth/signin'));
const ResetPassword = React.lazy(() => import('../pages/auth/reset-password'));
const SignForgot = React.lazy(() => import('../pages/auth/forgot'));
const Auth = React.lazy(() => import('../pages/auth'));
const MaterialListening = React.lazy(() => import('../pages/material-listening'));
const Favorite = React.lazy(() => import('../pages/favorite'));
const History = React.lazy(() => import('../pages/history'));
const Setting = React.lazy(() => import('../pages/setting'));
const Shadowing = React.lazy(() => import('../pages/shadowing/index'));
const Dictation = React.lazy(() => import('../pages/dictation/index'));
const MobileLanding = React.lazy(() => import('../pages/mobile-landing'));

/**
 * define all routes
 */
const Routes = () => (
  <Switch>
    <React.Suspense fallback={<></>}>
      <Route exact path="/" component={Home} />
      <Route exact path="/mobile-landing" component={MobileLanding} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/search" component={Search} />
      <Route exact path="/search/mobile" component={MobileSearch} />
      <Route exact path="/auth" component={Auth} />
      <Route exact path="/auth/forgot" component={SignForgot} />
      <Route exact path="/auth/signup" component={Signup} />
      <Route exact path="/auth/signin" component={Singin} />
      <Route exact path="/auth/reset-password" component={ResetPassword} />
      <Route exact path="/setting" component={Setting} />
      <AuthRoute exact path="/favorite" component={Favorite} />
      <AuthRoute exact path="/history" component={History} />
      <Route exact path="/summary/:uid" component={MaterialDetail} />
      <Route exact path="/reading/:uid" component={MaterialListening} />
      <AuthRoute exact path="/shadowing/:uid" component={Shadowing} />
      <AuthRoute exact path="/dictation/:uid" component={Dictation} />

      <Route exact path="/books/:tagUid" component={Search} />
      <Route exact path="/articles/:tagUid" component={Search} />
      <Route exact path="/english-tips/:tagUid" component={Search} />
    </React.Suspense>
  </Switch>
);

export default Routes;
