import request from '@/utils/request';

export default {
  /**
   * create listing mode record
   */
  createListeningModeRecord: (params: object) => request.post('/listening_mode_records', params),
  /**
   * update listing mode record
   */
  updateListeningModeRecord: (id: number, params: object) => request.put(`/listening_mode_records/${id}`, params),
};
