
import request from '@/utils/request';

export default {
  /**
   * favorite
   */
  favorite: (params: object) => request.post('/favorites', params),
  /**
   * delete favorite
   */
  deleteFavorite: (id: number | null) => request.delete(`/favorites/${id}`),
  /**
   * list my favorite
   */
  getFavoriteList: (params: object) => request.get('/favorites', { params }),
};
