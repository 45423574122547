import materials from './materials';
import user from './user';
import activity from './activity';
import favorite from './favorite';
import listeningModeRecord from './listeningModeRecord';
import shadowing from './shadowing';
import dictation from './dictation';
import payPalOrder from './payPalOrder';

export default {
  materials,
  user,
  activity,
  favorite,
  listeningModeRecord,
  shadowing,
  dictation,
  payPalOrder,
};
