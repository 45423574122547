import { all } from 'redux-saga/effects';
import home from './home';
import user from './user';

export interface IAction {
  type: string,
  data?: any,
}

export default function* rootSaga() {
  yield all([
    home(),
    user(),
  ]);
}
