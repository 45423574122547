/**
 * 国际化语言 reducer
 */
import en from '../languages/en';

// add local language data

const localLanguage = localStorage.getItem('language') || navigator.language;

// 初始化语言状态
const initState = {
  dialogVisible: false,
  localLanguage,
  messages: en.messages,
};

interface Action {
  type: String,
  language: String,
  data: object,
}

export default (state = initState, action: Action) => {
  switch (action.type) {
  case 'CHANGE_LANGUAGE': {
    return {
      localLanguage: action.language,
    };
  }
  case 'CHANGE_LANGUAGE_DATA': {
    return { ...state, ...action.data };
  }
  default: return state;
  }
};
