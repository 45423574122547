/* eslint-disable */
import _ from 'lodash';

const specialKeys = ['@'];

/**
 * simple keys to camel case
 * @param {*} obj
 */
const keyToCamelCase = (obj: object) => _.mapKeys(obj, (v, k) => {
  if (specialKeys.findIndex((sk) => k.indexOf(sk) > -1) > -1) {
    return _.toLower(k);
  }
  return _.camelCase(k);
});

/**
 * deep keys to camel case
 * @param {*} data
 */
export const deepKeyToCamelCase = (data: any): any => {
  if (_.isArray(data)) {
    return _.map(data, (d) => {
      if (_.isObject(d)) {
        return deepKeyToCamelCase(d);
      }
      return d;
    });
  }
  const obj = keyToCamelCase(data) as any;
  _.forEach(obj, (value, key) => {
    if (_.isObject(value)) {
      obj[key] = deepKeyToCamelCase(value);
    }
  });
  return obj;
};

/**
 * filter undefined value
 */
export const filterUndefined = (obj: object) => {
  const newObj = {};
  const keys = Object.keys(obj);
  for (let i = 0, len = keys.length; i < len; i += 1) {
    const key = keys[i];
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};


/*
  const arr = [{
    id: 1, name: 'aaa', arr2Id: 1, arr2Type: 'material',
  }];
  const arr2 = [{ id: 1, title: '123' }];
  const ret = mergeWithConnectKey(arr, arr2, 'arr2Id', 'id', 'arr2Type');

  ret = [{
    id: 1,
    name: 'aaa',
    arr2Id: 1,
    arr2Type: 'material',
    material: { id: 1, title: '123' },
  }];
*/
export const mergeWithConnectKey = (arr: Array<any>, arr2: Array<any>, arrKey: string, arr2Key: string, connectKey: string) => {
  const arr2Map = _.keyBy(arr2, arr2Key);
  return _.map(arr, (a) => {
    a[_.toLower(_.get(a, connectKey))] = arr2Map[_.get(a, arrKey)];
    return a;
  });
};
