import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { IConnectHome, IConnectLanguage } from '@/interfaces/IConnectState';
import store from '@/store';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LanguageSelect from '@/components/language-select';
import MainHeader from './pages/common/header';
import SwitchRouter from './routes';
import history from './routes/history';

const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface IProps extends IConnectHome, IConnectLanguage {}

/**
 * app layout
 */
const Layout = (props: IProps) => {
  const {
    home,
    language: {
      dialogVisible,
    },
  } = props;

  const [selectedLang, setSelectedLang] = useState('en');

  const hideToastMsg = () => {
    store.dispatch({
      type: 'HIDE_TOAST',
    });
  };


  return (
    <Router history={history}>
      {home.showHeader && (<MainHeader />)}
      <div className="middle">
        <SwitchRouter />
      </div>
      <Snackbar open={home.toastOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={hideToastMsg}>
        <Alert elevation={2} onClose={hideToastMsg} variant="filled" severity={home.toast.type}>
          { home.toast.msg }
        </Alert>
      </Snackbar>
      <Dialog
        open={dialogVisible}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="choose-language-title"
        aria-describedby="please choose language"
      >
        <DialogTitle>Please select your native language</DialogTitle>
        <DialogContent>
          <LanguageSelect value={selectedLang} onChange={setSelectedLang} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (selectedLang) {
                localStorage.setItem('language', selectedLang);
                store.dispatch({
                  type: 'SWITCH_LANGUAGE',
                  data: {
                    localLanguage: selectedLang,
                  },
                });
                store.dispatch({
                  type: 'CHANGE_LANGUAGE_DATA',
                  data: {
                    dialogVisible: false,
                  },
                });
              } else {
                store.dispatch({
                  type: 'SHOW_TOAST',
                  data: {
                    type: 'error',
                    msg: 'Please select language',
                  },
                });
              }
            }}
            color="primary"
            autoFocus
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </Router>
  );
};

export default connect((state: IProps) => ({
  home: state.home,
  language: state.language,
}))(Layout);
