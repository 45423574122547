import request from '@/utils/request';

export default {
  /**
   * get dictation mode record
   */
  getDictationModeRecord: (id: number, params: object) => request.get(`/dictation_mode_records/${id}`, { params }),
  /**
   * delete dictation mode record
   */
  deleteDictationModeRecord: (id: number) => request.delete(`/dictation_mode_records/${id}`),
  /**
   * create dictation mode record
   */
  createDictationModeRecord: (params: object) => request.post('/dictation_mode_records', params),
  /**
   * create dictaion mode part record
   */
  createDictationModePartRecord: (params: object) => request.post('/dictation_mode_part_records', params),
  /**
   * delete dictation mode part record
   */
  deleteDictationModePartRecord: (id: string) => request.delete(`/dictation_mode_part_records/${id}`),
};
