import request from '@/utils/request';

export default {
  /**
   * get shadowing mode record
   */
  getShadowingModeRecord: (id: number, params: object) => request.get(`/shadowing_mode_records/${id}`, { params }),
  /**
   * create shadowing mode record
   */
  createShadowingModeRecord: (params: object) => request.post('/shadowing_mode_records', params),
  /**
   * create shadowing mode part record
   */
  createShadowingModePartRecord: (formData: object) => request({
    // '/shadowing_mode_part_records', formData,  }
    url: '/shadowing_mode_part_records',
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }),
  /**
   * update shadowing mode part record
   */
  updateShadowingModePartRecord: (id: number, formData: object) => request({
    url: `/shadowing_mode_part_records/${id}`,
    method: 'PUT',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }),
};
